$main = $('.js-main');
$menu = $('#menu');
$menuIcon = $('.mobile-menu-icon');
$mobileMenu = $('.mobile-menu');
$header = $('#header');



function alink() {

  $(document).on('click', 'a', function () {
    var href = $(this).attr("href");



    if (href.indexOf(location.hostname) > -1 || href.indexOf(':') === -1) {

      $('body').addClass('loadOut');


      // $link = $('a')

      // $('a').on('click', function (e) {


      //   // e.preventDefault();
      //   $('body').addClass('loadOut');
      // })
    }
  })
}


alink();




function menu() {

  $menuIcon.on('click', function (e) {
    $(this).toggleClass("open");
    $($mobileMenu).toggleClass("open");
    $($main).toggleClass("menu-open");
    // $(nav).removeClass("hide");

  });
}

menu();



function animations() {

  var $section = $('.a-container')

  $section.each(function (index, element) {
    var wrapper = $(this);
    var elementWatcher = scrollMonitor.create(wrapper, {
      top: -250
    });

    if ($(this).hasClass('full-reveal')) {

      elementWatcher.fullyEnterViewport(function () {
        wrapper.addClass('active');
        wrapper.removeClass('opacity-0');
      });


    } else {

      elementWatcher.enterViewport(function () {
        wrapper.addClass('active');
        wrapper.removeClass('opacity-0');


      });

    }
  });

}

animations();

function parallax() {


  $(window).scroll(function () {
    var scrollTop = $(window).scrollTop(),
      logo = $('.logo-bg'),
      logo_offset = logo.offset()




    var $parallax_container = $('.parallax_container');

    $parallax_container.each(function () {

      var container_offset = $(this).offset(),
        object_offset = $(this).find('.parallax').offset(),
        h_start = scrollTop - container_offset.top,
        v_start = scrollTop;


      var left = h_start / -10 + 'px';
      var right = h_start / 10 + 'px';
      var top = v_start / -7 + 'px';
      var bottom = v_start / 7 + 'px';


      $(this).find('.parallax-left').css('transform', 'translate3d(' + left + ', 0 ,0)');
      $(this).find('.parallax-right').css('transform', 'translate3d(' + right + ', 0 ,0)');
      $(this).find('.parallax-top').css('transform', 'translate3d(0, ' + top + ' ,0)');
      $(this).find('.parallax-bottom').css('transform', 'translate3d(0, ' + bottom + ' ,0)');

    })
  })


}

parallax();

function scrollingTextAnimation() {

  $(window).scroll(function () {
    var scrollTop = $(window).scrollTop()

    $(".scrolling-text.top").find('.line').each(function (index) {

      var text_offset = $(this).offset(),
        // wrapper_offset = $(this).$('.scrolling-text').offset(),
        h_start = text_offset.top - (scrollTop * 2),
        v_start = scrollTop;

      var left = ((h_start) * -1) + 'px';


      if (left > '0px') {

        $(this).css('background-position', left + ', 0');
      }

      // if (left > '0px') { var p = 'yes' } else { var p = 'no' }


      // console.log(p)

    });
  })
}

$('.line').each(function (index, element) {
  var wrapper = $(this);
  var elementWatcher = scrollMonitor.create(wrapper, {
    top: -300
  });

  elementWatcher.enterViewport(function () {
    wrapper.addClass('active');
    scrollingTextAnimation();
  });
})







function nav() {

  var prev = 0;
  var $window = $(window);

  $(document).ready(function () {
    $window.on('scroll', function () {

      if (!$($main).hasClass("menu-open")) {

        var scrollTop = $window.scrollTop();

        if (scrollTop > 300) {
          $header.toggleClass('hide', scrollTop > prev);
          prev = scrollTop;
        }
      }
    })
  })

}
nav();




var testimonialSlider = new Swiper('.testimonial-swiper.swiper', {
  speed: 400,
  autoHeight: true,
  spaceBetween: 0,
  grabCursor: true,
  navigation: {
    nextEl: '.testimonial-button-next',
    prevEl: '.testimonial-button-prev',
  },
  loop: true,
  pagination: {
    el: '.testimonial-pagination.swiper-pagination',
    clickable: true,

    type: 'bullets',
    renderBullet: function (index) {
      return '<div class="header6 flex mr-24 z-40 text-gold relative swiper-pagination-bullet "><div class="z-10 relative block">' + (index + 1) + '</div><div class="indicator"></div></div>';
    },

  },
  bulletActiveClass: 'active'
});


var calloutSlider = new Swiper('.callout-swiper.swiper', {
  speed: 400,
  autoHeight: true,
  spaceBetween: 50,
  grabCursor: true,
  slidesPerView: 1,

  breakpoints: {
    768: {
      slidesPerView: 2.2,
      spaceBetween: 50
    },
  },
  loop: false,
  pagination: {
    el: '.callout-pagination.swiper-pagination',
    clickable: true,

    type: 'bullets',
    renderBullet: function (index) {
      return '<div class="text-numbers leading-100 flex mr-24 font-bold relative  swiper-pagination-bullet ">' + '0' + (index + 1) + '</div>';
    },
  },
  bulletActiveClass: 'active'

});




var approachSlider = new Swiper('.approach-swiper.swiper', {
  speed: 8000,
  autoHeight: true,
  spaceBetween: 40,
  grabCursor: true,
  slidesPerView: 1.5,
  autoplay: {
    delay: 0,
    // disableOnInteraction: false,
  },
  watchSlidesProgress: true,



  breakpoints: {
    768: {
      slidesPerView: 2.5,
      spaceBetween: 50
    },
  },
  loop: true


});



var currentInvestmentSilder = new Swiper('.current-investment-swiper.swiper', {
  speed: 400,
  autoHeight: false,
  spaceBetween: 20,
  grabCursor: true,
  slidesPerView: 1.1,
  navigation: {
    nextEl: '.investment-button-next',
    prevEl: '.investment-button-prev',
  },

  breakpoints: {
    768: {
      slidesPerView: 2.1,
      spaceBetween: 20
    },
    1024: {
      slidesPerView: 2.2,
      spaceBetween: 40
    },
  },


});

